import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <h2>Cookie Policy</h2>
    <p>Waiting for a content…</p>
  </Layout>
);

export default IndexPage
